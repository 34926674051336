import React from 'react'
import DeckGL from '@deck.gl/react'
import { BitmapLayer, IconLayer, PolygonLayer } from '@deck.gl/layers'
import { IconMapping } from '@deck.gl/layers/dist/icon-layer/icon-manager'
import { Box, Drawer } from '@mui/material'
import { useAppDispatch, useAppSelector } from './app/hook'
import { Place, Zone } from './model'
import iconMapping from './icon-atlas.json'
import iconAtlas from './icon-atlas.png'
import { TileLayer } from 'deck.gl'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { ConstraintsList } from './components/ConstraintsList'
import { getBounds } from './api'
import { setBeaconZone } from './features/constraintsSlice'
import { v4 as uuid } from 'uuid'

const drawerWidth = 240

const unpackAtlasMapping = (): IconMapping => {
    const mapping: IconMapping = {}
    for (const [key, value] of Object.entries(iconMapping.frames)) {
        mapping[key] = {
            x: value.frame.x,
            y: value.frame.y,
            width: value.frame.w,
            height: value.frame.h,
            mask: true,
        }
    }
    return mapping
}

const AppContent = () => {
    const constraints = useAppSelector((state) => state.constraints)
    const viewState = useAppSelector((state) => state.view)
    const dispatch = useAppDispatch()

    if (!constraints.beaconZone) {
        getBounds(
            Object.values(constraints.beacons).map((beacon) => beacon.geom)
        ).then((geom) => {
            if (geom)
                dispatch(
                    setBeaconZone({
                        id: uuid(),
                        name: 'Beacons',
                        geom: geom,
                    } as Zone)
                )
        })
    }

    const layers = [
        new TileLayer({
            id: 'TileLayer',
            data: 'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png',
            maxZoom: viewState.lastSet.maxZoom,
            minZoom: viewState.lastSet.minZoom,

            renderSubLayers: (props) => {
                const { boundingBox } = props.tile

                return new BitmapLayer(props, {
                    data: undefined,
                    image: props.data,
                    bounds: [
                        boundingBox[0][0],
                        boundingBox[0][1],
                        boundingBox[1][0],
                        boundingBox[1][1],
                    ],
                })
            },
            pickable: true,
        }),
        constraints.beaconZone &&
            new PolygonLayer<Zone>({
                id: 'BeaconZone',
                data: [constraints.beaconZone],
                getPolygon: (d: Zone) => d.geom.exterior,
                getFillColor: () => [0, 0, 255, 50],
                getLineWidth: 20,
                lineWidthMinPixels: 1,
            }),
        !viewState.beaconVisibility.disabled &&
            new IconLayer<Place>({
                id: 'Beacons',
                data: Object.values(constraints.beacons),
                getColor: () => [0, 0, 255],
                getPosition: (d: Place) => d.geom,
                getIcon: () => 'star',
                getSize: 40,
                iconAtlas: iconAtlas,
                iconMapping: unpackAtlasMapping(),
                pickable: true,
            }),
    ]

    return (
        <Box display="flex">
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <ConstraintsList />
            </Drawer>

            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <DeckGL
                    initialViewState={viewState.lastSet}
                    controller
                    layers={layers}
                />
            </Box>
        </Box>
    )
}

function App() {
    return (
        <Provider store={store}>
            <AppContent />
        </Provider>
    )
}

export default App
