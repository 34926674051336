import {
    Box,
    Collapse,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import { setBeaconVisibility } from '../features/viewSlice'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarIcon from '@mui/icons-material/Star'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../app/hook'
import { removeBeacon } from '../features/constraintsSlice'

export const ConstraintsList = () => {
    const dispatch = useAppDispatch()

    const viewState = useAppSelector((state) => state.view)
    const constraints = useAppSelector((state) => state.constraints)

    return (
        <div>
            <List>
                <ListItem>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                setBeaconVisibility({
                                    ...viewState.beaconVisibility,
                                    disabled:
                                        !viewState.beaconVisibility.disabled,
                                })
                            )
                        }
                    >
                        {viewState.beaconVisibility.disabled ? (
                            <VisibilityOffIcon />
                        ) : (
                            <VisibilityIcon />
                        )}
                    </IconButton>
                    <Box width={16}></Box>
                    <ListItemText primary="Beacons" />
                    <IconButton
                        onClick={() =>
                            dispatch(
                                setBeaconVisibility({
                                    ...viewState.beaconVisibility,
                                    open: !viewState.beaconVisibility.open,
                                })
                            )
                        }
                    >
                        {viewState.beaconVisibility.open ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </IconButton>
                </ListItem>
                <Collapse
                    in={viewState.beaconVisibility.open}
                    timeout="auto"
                    unmountOnExit
                >
                    <List dense component="div" disablePadding>
                        {Object.values(constraints.beacons).map((place) => {
                            return (
                                <ListItem
                                    key={place.name}
                                    sx={{ pl: 4 }}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() =>
                                                dispatch(removeBeacon(place.id))
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemIcon>
                                        <StarIcon style={{ color: 'blue' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={place.name} />
                                </ListItem>
                            )
                        })}
                    </List>
                </Collapse>
            </List>
            {viewState.beaconVisibility.open && <Divider />}
        </div>
    )
}
