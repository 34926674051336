import { configureStore, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'

import constraintReducer, {
    ConstraintsState,
} from '../features/constraintsSlice'
import viewReducer, { ViewManagementState } from '../features/viewSlice'

export const store = configureStore({
    reducer: {
        constraints: constraintReducer,
        view: viewReducer,
    },
})

type State = { constraints: ConstraintsState; view: ViewManagementState }
export type AppDispatch = ThunkDispatch<State, void, UnknownAction>
export type RootState = ReturnType<typeof store.getState>
