import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Place, Zone } from '../model'
import { v4 as uuid } from 'uuid'

type BeaconMap = { [id: string]: Place }

export interface ConstraintsState {
    beacons: BeaconMap
    beaconZone?: Zone
}

const initialBeacons = [
    {
        id: uuid(),
        geom: [-75.16359284824831, 39.952419024297484],
        name: 'Philly',
    },
    {
        id: uuid(),
        geom: [-74.0062170945764, 40.71254879620821],
        name: 'NYC',
    },
]

export const initialState: ConstraintsState = {
    beacons: initialBeacons.reduce(
        (o, beacon) => ({ ...o, [beacon.id]: beacon }),
        {}
    ),
}

export const constraintsSlice = createSlice({
    name: 'constraints',
    initialState,
    reducers: {
        addBeacon: (state, action: PayloadAction<Place>) => {
            const newBeacon = action.payload
            state.beacons[newBeacon.id] = newBeacon
            state.beaconZone = undefined
            return state
        },
        removeBeacon: (state, action: PayloadAction<string>) => {
            const id = action.payload
            if (!(id in state.beacons)) return
            delete state.beacons[id]
            state.beaconZone = undefined
            return state
        },
        setBeaconZone: (state, action: PayloadAction<Zone>) => {
            const zone = action.payload
            state.beaconZone = zone
            return state
        },
    },
})

export const { addBeacon, removeBeacon, setBeaconZone } =
    constraintsSlice.actions

export default constraintsSlice.reducer
