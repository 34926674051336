import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { FlyToInterpolator, MapViewState } from '@deck.gl/core'
import { Bounds } from '../bounds'
import { Zone } from '../model'

export interface MapDimensions {
    pxHeight: number
    pxWidth: number
}

export interface SetVisibility {
    open: boolean
    disabled: boolean
}

export interface ViewManagementState {
    lastSet: MapViewState
    beaconVisibility: SetVisibility
}

export const initialState: ViewManagementState = {
    lastSet: {
        longitude: -74.68977018432153,
        latitude: 40.32173665114077,
        zoom: 7,
        minZoom: 1,
        maxZoom: 21,
    },
    beaconVisibility: { open: true, disabled: false },
}

const WORLD_DIM: MapDimensions = { pxHeight: 256, pxWidth: 256 }

// Remixed from https://stackoverflow.com/a/13274361
function getBoundsZoomLevel(bounds: Bounds, mapDim: MapDimensions) {
    const latRad = (lat: number) => {
        const sin = Math.sin((lat * Math.PI) / 180)
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    }

    const zoom = (mapPx: number, worldPx: number, fraction: number) => {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
    }

    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()

    const latFraction = (latRad(ne[1]) - latRad(sw[1])) / Math.PI

    const lngDiff = ne[0] - sw[0]
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

    const latZoom = zoom(mapDim.pxHeight, WORLD_DIM.pxHeight, latFraction)
    const lngZoom = zoom(mapDim.pxWidth, WORLD_DIM.pxWidth, lngFraction)

    return Math.min(latZoom, lngZoom, initialState.lastSet.maxZoom!)
}

export const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {
        setNewState: (state, action: PayloadAction<MapViewState>) => {
            state.lastSet = action.payload
            return state
        },
        focusZone: (state, action: PayloadAction<Zone>) => {
            const zone = action.payload

            console.log(`Focusing on ${zone.name}`)
            const bounds = zone.bounds()
            if (bounds == null) return state

            const center = bounds.getCenter()
            const zoom = getBoundsZoomLevel(bounds, WORLD_DIM)

            const randomOffset = () => {
                return (Math.random() - 0.5) * 1e-6
            }

            // Add small, random number to lng/lat so that zoom always works.
            return {
                ...state,
                lastSet: {
                    ...state.lastSet,
                    ...{
                        pitch: 0,
                        zoom: zoom,
                        longitude: center[0] + randomOffset(),
                        latitude: center[1] + randomOffset(),
                        transitionDuration: 1000,
                        transitionInterpolator: new FlyToInterpolator(),
                    },
                },
            }
        },
        setBeaconVisibility(state, action: PayloadAction<SetVisibility>) {
            state.beaconVisibility = action.payload
            return state
        },
    },
})

export const { setNewState, focusZone, setBeaconVisibility } = viewSlice.actions

export default viewSlice.reducer
