import { GeoCoordinate, GeoPolygon } from './model'
import axios from 'axios'

const url: URL = new URL(
    process.env.REACT_APP_API_URL ?? 'https://api.where2be.rwies.com'
)

const instance = axios.create({
    baseURL: url.toString(),
    timeout: 1000,
})

export const getBounds = async (
    coords: GeoCoordinate[]
): Promise<GeoPolygon | undefined> => {
    try {
        const response = await instance.post('/bounds', coords)
        return response.data
    } catch (error) {
        console.log(error)
        return
    }
}
